import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NbAlertModule, NbIconModule} from '@nebular/theme';
import {MaterialModule} from 'app/material.module';
import {SearchBarComponent} from 'app/modules/search/bar/search-bar.component';

@NgModule({
  imports: [
    CommonModule,
    NbAlertModule,
    MaterialModule,
    FormsModule,
    NbIconModule
  ],
  declarations: [
    SearchBarComponent
  ],
  exports: [
    SearchBarComponent
  ]
})
export class SearchBarModule {
}
