import {Injectable} from '@angular/core';
import {decodeJwtPayload} from '@nebular/auth';
import {NbRoleProvider} from '@nebular/security';
import {SMLAuthService} from 'app/@core/utils/sml-auth.service';
import {Observable, of} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {ExternalAuthToken} from './external-auth.token';
import {AuthADFSToken} from './internal-auth.strategy';

@Injectable()
export class SMLRoleProvider extends NbRoleProvider {
  constructor(private authService: SMLAuthService) {
    super();
  }

  getDecodedToken(token: AuthADFSToken | ExternalAuthToken | null): {[key: string]: any} {
    if (token) {
      const payload = token.getValue() || '';

      return decodeJwtPayload(payload);
    }

    return {};
  }

  getRole(): Observable<string> {
    return this.authService.onTokenChange()
      .pipe(
        map((token: AuthADFSToken | ExternalAuthToken) => {
          if (!token.isValid()) {
            return null;
          }

          return token;
        }),
        map(this.getDecodedToken),
        map((decoded) => {
          if (decoded) {
            const roles = decoded.roles.split(',')[0];
            const split = roles.split('_');
            let role = split[0].toLowerCase();
            if (split.length > 1) {
              role = split[0].toLowerCase();
              role += split[1].charAt(0).toUpperCase();
              role += split[1].slice(1).toLowerCase();
            }
            return role;
          }

          return 'guest';
        })
      );
  }

  getLocation(): any {
    return this.authService.onTokenChange().pipe(map(this.getDecodedToken), pluck('location'), map(v => Number(v)));
  }
}
