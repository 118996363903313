import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Observable, Subject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

export interface AlertMessage {
  id?: string;
  type: ('success' | 'danger' | 'error' | 'clear');
  text?: string;
}

@Injectable({providedIn: 'root'})
export class AlertService implements OnDestroy {
  private subject = new Subject<AlertMessage>();
  private keepAfterNavigationChange = false;
  private defaultId = 'default-alert';

  private subscriptions: Subscription[] = [];

  constructor(private router: Router) {
    // clear alert message on route change
    this.subscriptions.push(
      router.events
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          if (this.keepAfterNavigationChange) {
            // only keep for a single location change
            this.keepAfterNavigationChange = false;
          } else {
            // clear alert
            this.subject.next();
          }
        }
      })
    );
  }

  success(message: string, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.alert({type: 'success', text: message});
  }

  danger(message: string | HttpErrorResponse, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    let text: string = <string>message;
    if (typeof message === 'object') {
      text = message.error.message || message.statusText;
    }
    this.alert({type: 'danger', text: text});
  }

  flatAndDispatchFieldConstraints(message: string | HttpErrorResponse): void {
    let text: string = <string>message;
    if (typeof message === 'object') {
      text = message.error.message || message.statusText;
      if (message.error && 'fieldConstraints' in message.error) {
        text += ':\n';
        text += message.error.fieldConstraints.reduce((acc, next) => {
          acc += `- ${next.fieldPath}: ${next.message};\n`;
          return acc;
        }, '');
      }
    }
    this.alert({type: 'danger', text: text});
  }

  error(message: string, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.alert({type: 'error', text: message});
  }

  onAlert(id = this.defaultId): Observable<AlertMessage> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  clear(id = this.defaultId) {
    this.subject.next({id: id, type: 'clear'});
  }

  alert(alert: AlertMessage) {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
