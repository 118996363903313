import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {MainAuthGuard} from 'app/main-auth-guard.service';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('app/modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'contracts',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/contracts/contracts.module').then(m => m.ContractsModule)
  },
  {
    path: 'appointments',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/appointments/appointments.module').then(m => m.AppointmentsModule)
  },
  {
    path: 'search',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/search/result/search-result.module').then(m => m.SearchResultModule)
  },
  {
    path: 'user-management',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/user-management/user-management.module').then(m => m.UserManagementModule)
  },  
  {
    path: 'supplier-management',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/supplier-management/supplier-management.module').then(m => m.SupplierManagementModule)
  },
  {
    path: 'de-registration',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/de-registration/de-registration.module').then(m => m.DeRegistrationModule)
  },
  {
    path: 'dad-warnings',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/dad-warnings/dad-warnings.module').then(m => m.DadWarningsModule)
  },
  {
    path: 'dad-responses',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/dad-responses/dad-responses.module').then(m => m.DadResponsesModule)
  },
  {
    path: 'fleet',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/fleet/fleet.module').then(m => m.FleetModule)
  },
  {
    path: 'fleet-transports',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/fleet-transports/fleet-transports.module').then(m => m.FleetTransportsModule)
  },
  {
    path: 'resources-management',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/resources-management/resources-management.module').then(m => m.ResourcesManagementModule)
  },
  {path: '', redirectTo: 'pages', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages'}
];

const config: ExtraOptions = {
  useHash: false,
  onSameUrlNavigation: 'reload',
  urlUpdateStrategy: 'eager'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
