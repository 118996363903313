import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {NB_AUTH_STRATEGIES, NbAuthService, NbTokenService} from '@nebular/auth';
import {collectQueryParams} from 'app/ui/helpers/request.helper';
import {Observable, of as observableOf} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class SMLAuthService extends NbAuthService {

  constructor(
    protected tokenService: NbTokenService,
    @Inject(NB_AUTH_STRATEGIES) protected strategies,
    private router: Router
  ) {
    super(tokenService, strategies);
  }

  isAuthenticatedOrRefresh(routerStateSnapshot?: RouterStateSnapshot, activatedRouteSnapshot?: ActivatedRouteSnapshot): Observable<boolean> {
    const url = routerStateSnapshot !== undefined ? encodeURIComponent(routerStateSnapshot.url.split('?')[0]) : '';
    const params = activatedRouteSnapshot !== undefined ? encodeURIComponent(JSON.stringify(Object.fromEntries(collectQueryParams(activatedRouteSnapshot)))) : '';
    return this.getToken()
    .pipe(
      switchMap(token => {
        if (token.getValue() && !token.isValid()) {
          return this.refreshToken(token.getOwnerStrategyName(), token)
          .pipe(
            switchMap(res => {
              if (res.isSuccess()) {
                return this.isAuthenticated();
              } else {
                this.router.navigate(['auth/login'], {
                  queryParams: {
                    from: url,
                    params: params
                  }
                });
                return observableOf(false);
              }
            })
          );
        } else {
          return observableOf(token.isValid());
        }
      }));
  }
}
