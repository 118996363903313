import {NbAuthJWTToken} from '@nebular/auth';

export class ExternalAuthToken extends NbAuthJWTToken {
  static NAME = 'sml:auth:external:token';

  isValid(): boolean {
    return super.isValid()
      && (!this.getTokenExpDate()
        || new Date().getTime() < (this.getTokenExpDate().getTime() - 1800000));
  }

  getParsedToken(): {
    exp: number,
    iat: number,
    permissions: {
      menu: string[],
    },
    roles: string,
    firstName: string,
    lastName: string,
    sub: string,
    vendorId: number,
    vendorName: string,
    location: string
  } {
    return this.getPayload();
  }
}


