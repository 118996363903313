import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Validator} from '../dynamic-field';
import {SelectOptionInterface} from './select-option.interface';
import {SelectAbstractDirective} from './select.abstract';

@Component({
  selector: 'app-select',
  template: `
    <mat-form-field class="container-fluid mt-auto" [formGroup]="group">
      <mat-select [placeholder]="label" [formControlName]="name" [compareWith]="compareObjects">
        <mat-option *ngFor="let item of options" [value]="item.value">{{item.title}}</mat-option>
      </mat-select>
      <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
        <mat-error
          *ngIf="group.get(name).hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styles: []
})
export class SelectComponent extends SelectAbstractDirective implements OnInit {
  optionsPromise?: BehaviorSubject<SelectOptionInterface[]> = new BehaviorSubject(null);
  type = SelectComponent;
  label: string;
  name: string;
  options: SelectOptionInterface[] = [];
  validations: Validator[] = [];
  disabled = false;

  ngOnInit() {
    this.pipeSubscription(this.optionsPromise)
    .subscribe(data => {
      if (data) {
        this.options = data;
      }
    });
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.toString() === o2.toString();
  }
}
