import {Component, OnInit, Input} from '@angular/core';
import {ButtonAbstract} from './button.abstract';

@Component(
  {
    selector: 'app-button',
    template: `
      <div class="form-group container-fluid mt-auto" [formGroup]="group">
        <button
          [style.margin]="styles.margin"
          [style.padding]="styles.padding"
          [style.width]="styles.width"
          [disabled]="isDisabled"
          type="{{actionType}}"
          mat-raised-button
          color="{{color}}"
          (click)="actionHandler()"
          id="{{buttonId}}"
          class="btn btn-md btn-primary rounded-0">{{label.toUpperCase()}}</button>
      </div>
    `,
    styles: []
  }
)
export class ButtonComponent extends ButtonAbstract implements OnInit {
  type = ButtonComponent;
  label = '';
  actionType = 'submit';
  color = 'primary';
  buttonId?: string;
  disabled: boolean | (() => boolean) = false;
  callbackScope?: any;
  callbackMethod?: (scope: any) => void;
  styles?: any = {};

  constructor() {
    super();
  }

  get isDisabled() {
    if (typeof this.disabled === 'function') {
      return this.disabled();
    }

    return this.disabled;
  }

  actionHandler() {
    if (typeof this.callbackMethod === 'function'
      && this.actionType !== 'submit') {
      this.callbackMethod(this.callbackScope);
    }
  }

  ngOnInit() {
  }
}
