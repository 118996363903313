import {ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from './field.interface';

@Directive({
  selector: '[appDynamicField]'
})
export class DynamicFieldDirective implements OnInit {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  componentRef: any;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {
  }

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(this.field.type);
    this.componentRef = this.container.createComponent(factory);
    for (const key in
      this.field) {
      if (key === 'type') {
        continue;
      }
      this.componentRef.instance[key] = this.field[key];
    }
    this.componentRef.instance.group = this.group;
  }
}
