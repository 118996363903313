import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {UserService} from '../../../@core/utils/user.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'feedback-button',
  template: ``
})
export class FeedbackButtonComponent implements OnInit {

  readonly feedBackJQueryPluginSrc = 'https://jira.central.sixt-leasing.com/s/d41d8cd98f00b204e9800998ecf8427e-CDN' +
    '/-5ckngx/813002/7c58d55d3a49d3c4733c4f1329ccc5df/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=408c767f';
  readonly feedBackCollectorPluginSrc = 'https://jira.central.sixt-leasing.com/s/719f8bcadd0a476c1c2b1ad68dc752b5-T' +
    '/-5ckngx/813002/7c58d55d3a49d3c4733c4f1329ccc5df/4.0.3/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector' +
    '/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-UK&collectorId=408c767f';

  constructor(private userService: UserService,
              @Inject(DOCUMENT) private document,
              private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.userService.getAuthenticatedUserInfo().subscribe(user => {
      if (undefined !== user) {
        const feedBackJQueryPlugin = this.document.createElement('script');
        feedBackJQueryPlugin.type = 'text/javascript';
        feedBackJQueryPlugin.src = this.feedBackJQueryPluginSrc;
        this.elementRef.nativeElement.appendChild(feedBackJQueryPlugin);

        const feedBackCollectorPlugin = this.document.createElement('script');
        feedBackCollectorPlugin.type = 'text/javascript';
        feedBackCollectorPlugin.src = this.feedBackCollectorPluginSrc;
        this.elementRef.nativeElement.appendChild(feedBackCollectorPlugin);
      }
    });
  }

}
