import {ActivatedRouteSnapshot} from '@angular/router';

export type PageableRequestDirection = 'desc' | 'asc' | string;

export interface PageableRequestParameters {
  page?: number;
  size?: number;
  direction?: PageableRequestDirection;
  sort?: string | string[];
  secondSort?: string;
  tabType?: string;

  [key: string]: string | number | boolean | string[];
}

export function createQueryParams(params: PageableRequestParameters): string {
  const queryParams = new URLSearchParams();

  Object.entries(params)
    .filter(([, value]) => typeof value !== 'undefined')
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((value) => {
          queryParams.append(key, value);
        });
      } else {
        queryParams.append(key, `${value}`);
      }
    });

  return queryParams.toString();
}

export function convertToSortParams(params: PageableRequestParameters): PageableRequestParameters {
  const sortParams: string[] = [];

  if (params.sort) {
    sortParams.push(`${params.sort},${params.direction}`);
  }

  if (params.secondSort) {
    sortParams.push(`${params.secondSort},asc`);
  }

  return {
    page: params.page || 0,
    size: params.size || 20,
    sort: sortParams,
  };
}

export function collectPathParams(snapshot: ActivatedRouteSnapshot): Map<string, string> {
  const map = new Map<string, string>();
  snapshot.paramMap.keys.map((key: string) => {
    map.set(key, snapshot.paramMap.get(key));
  });
  return !!snapshot.firstChild ? new Map<string, string>([...map, ...collectPathParams(snapshot.firstChild)]) : map;
}

export function collectQueryParams(snapshot: ActivatedRouteSnapshot): Map<string, string> {
  const map = new Map<string, string>();
  snapshot.queryParamMap.keys.map((key: string) => {
    map.set(key, snapshot.queryParamMap.get(key));
  });
  return !!snapshot.firstChild ? new Map<string, string>([...map, ...collectQueryParams(snapshot.firstChild)]) : map;
}
