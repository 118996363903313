import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ContractOrderDto} from 'app/domain/dto/contract.interface';
import {environment} from 'environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable(
  {
    providedIn: 'root'
  }
)
export class BpmnService {

  baseUrl = environment.baseUrl;
  vehicleUrlPrefix = environment.vehicleServicePrefix;
  apiVersion = environment.apiVersion;

  constructor(private http: HttpClient) {
  }

  createContract(contract: ContractOrderDto) {
    const url = this.baseUrl + this.vehicleUrlPrefix + this.apiVersion + '/contract';
    return this.http.post<ContractOrderDto>(url, JSON.stringify(contract), httpOptions);
  }

  createRegistrationBySixt(registration) {
    const url = this.baseUrl + this.vehicleUrlPrefix + this.apiVersion + '/registration/sixt';
    return this.http.post(url, JSON.stringify(registration), httpOptions);
  }

  createRegistrationByCustomer(registration) {
    const url = this.baseUrl + this.vehicleUrlPrefix + this.apiVersion + '/registration/customer';
    return this.http.post(url, JSON.stringify(registration), httpOptions);
  }

}
