import {Component, EventEmitter, Input} from '@angular/core';
import {PageEvent} from '@angular/material/paginator/paginator';

@Component({
  selector: 'infinity-table-next',
  template: `
    <div (click)="nextPage()" class="text-center">
      <span class="cursor-pointer">
        LOAD MORE <i class="fa fa-caret-down"></i>
      </span>
    </div>
  `,
  styles: [`
    div {
      color: #1A103F;
      margin-bottom: 15px;
    }
  `]
})
export class InfinityTableNextComponent {
  @Input() length = Infinity;
  @Input() pageSize = 0;

  pageIndex = 0;

  readonly page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  nextPage(): void {
    const previousPageIndex = this.pageIndex;
    this.pageIndex++;
    this._emitPageEvent(previousPageIndex);
  }

  private _emitPageEvent(previousPageIndex: number) {
    this.page.emit({
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length
    });
  }
}
