import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NbEvaIconsModule} from '@nebular/eva-icons';

import {NbDateFnsDateModule} from '@nebular/date-fns';

import {NbSecurityModule} from '@nebular/security';

import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonModule,
  NbCalendarKitModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbChatModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbThemeModule,
  NbToastrModule,
  NbToggleModule,
  NbTooltipModule,
  NbUserModule,
  NbWindowModule,
} from '@nebular/theme';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {
  AlertComponent,
  BackButtonComponent,
  BaseComponent,
  ButtonComponent,
  CardComponent,
  CheckboxComponent,
  ContainerComponent,
  DateComponent,
  DynamicFieldDirective,
  DynamicFormComponent,
  FileUploaderComponent,
  FooterComponent,
  HeaderComponent,
  InfinityTableNextComponent,
  InputComponent,
  ListContainerComponent,
  ListInputComponent,
  NumberInputComponent,
  SelectComponent,
  TabComponent,
  TabsetComponent,
  TextareaComponent,
  ThemeSettingsComponent,
  TinyMCEComponent
} from 'app/@theme/components';
import {AngularFileUploaderModule} from 'app/@theme/components/angular-file-uploader/angular-file-uploader.module';
import {ReusableInputComponent} from 'app/@theme/components/input/reusable-input.component';
import {ReusableSelectComponent} from 'app/@theme/components/select/reusable-select.component';

import {SampleLayoutComponent} from 'app/@theme/layouts';

import {
  CapitalizePipe,
  EvaIconsPipe,
  GermanTimezonePipe,
  FlatObjPipe,
  NumberWithCommasPipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  PrettyPrintPipe
} from 'app/@theme/pipes';

import {CORPORATE_THEME} from 'app/@theme/styles/theme.corporate';
import {MaterialModule} from 'app/material.module';
import {SearchBarModule} from 'app/modules/search/bar/search-bar.module';
import {ImportFileHistoryErrorDialogComponent} from './components/import-file-history/import-file-history-error-dialog.component';
import {FeedbackButtonComponent} from './components/feedback-button/feedback-button.component';

const BASE_MODULES = [RouterModule, CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, SearchBarModule];

const NB_MODULES = [
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  AngularFileUploaderModule,
  NbSecurityModule, // *nbIsGranted directive,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbListModule,
  NbToastrModule,
  NbInputModule,
  NbAccordionModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbChatModule,
  NbTooltipModule,
  NbCalendarKitModule,
  NbBadgeModule,
  NbToggleModule,
  NbDateFnsDateModule
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  ThemeSettingsComponent,
  SampleLayoutComponent,
  BaseComponent,
  TinyMCEComponent,
  AlertComponent,
  ButtonComponent,
  CardComponent,
  CheckboxComponent,
  ContainerComponent,
  ListContainerComponent,
  DateComponent,
  DynamicFieldDirective,
  DynamicFormComponent,
  InputComponent,
  ReusableInputComponent,
  TextareaComponent,
  ListInputComponent,
  NumberInputComponent,
  SelectComponent,
  ReusableSelectComponent,
  TabComponent,
  TabsetComponent,
  FileUploaderComponent,
  InfinityTableNextComponent,
  BackButtonComponent,
  FeedbackButtonComponent,
  ImportFileHistoryErrorDialogComponent
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  PrettyPrintPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
  GermanTimezonePipe,
  FlatObjPipe
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'corporate'
    },
    [CORPORATE_THEME]
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDatepickerModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers,
  ...NbDateFnsDateModule.forRoot({}).providers
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES],
  entryComponents: [
    InputComponent,
    NumberInputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    ContainerComponent,
    ListContainerComponent,
    ListInputComponent,
    CheckboxComponent,
    CardComponent,
    FileUploaderComponent,
    TextareaComponent,
    ReusableInputComponent,
    ReusableSelectComponent
  ]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS]
    };
  }
}
