import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from 'app/@core/utils/analytics.service';

@Component({
  selector: 'app-component',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(private analytics: AnalyticsService) {
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
  }
}
