import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ButtonComponent} from 'app/@theme/components/button';
import {CardComponent} from 'app/@theme/components/card';
import {ContainerComponent, ListContainerComponent} from 'app/@theme/components/container';
import {FieldConfig} from 'app/@theme/components/dynamic-field';
import * as moment from 'moment';

@Component({
  exportAs: 'dynamicForm',
  selector: 'app-dynamic-form',
  template: `
    <form class="container-fluid mt-5" [formGroup]="form" (submit)="onSubmit($event)">
      <ng-container *ngFor="let item of fields;">
        <ng-container *ngIf="isArray(item)">
          <div class="row">
            <ng-container *ngFor="let field of item;">
              <div class="col">
                <ng-container appDynamicField [field]="field"
                              [group]="resolveFormForNestedComponent(field)">
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </form>
  `,
  styles: []
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: FieldConfig[][] = [];

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  get value() {
    return this.formatDates(this.form.value);
  }

  ngOnInit() {
    this.form = this.createControls(this.fields);
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      this.submit.emit(this.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createControls(fields: any[]) {
    const formGroup = this.fb.group({});
    fields.forEach((group: any[]) => {
      group.forEach((field) => {
        if (field.type === ButtonComponent) {
          return;
        }
        const control = this.createFieldControl(field);
        if (field.type === CardComponent) {
          for (const item in
            (<FormGroup>control).controls) {
            if ((<FormGroup>control).controls[item] instanceof FormGroup) {
              formGroup.addControl(item, (<FormGroup>control).controls[item]);
            }
          }
          return;
        }
        formGroup.addControl(field.name, control);
      });
    });
    return formGroup;
  }

  createFieldControl(field: any): AbstractControl {
    let control: AbstractControl;
    if (field.type === ContainerComponent) {
      control = this.createControls(field.items);
    } else if (field.type === CardComponent) {
      control = this.createControls(field.childItems);
    } else if (field.type === ListContainerComponent) {
      control = this.fb.array([]);
      field.items.forEach((item) => {
        const itemControl = this.createFieldControl(item);
        (<FormArray>control).push(itemControl);
      });
    } else if (field.type !== ButtonComponent) {
      const validations = field.validations || [];
      control = this.fb.control(
        {
          value: typeof field.value === 'undefined' ? '' : field.value,
          disabled: typeof field.disabled === 'undefined' ? false : field.disabled
        },
        this.bindValidations(validations)
      );
    }
    return control;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
      control.markAsTouched({onlySelf: true});
    });
  }

  isArray(val: any) {
    return Array.isArray(val);
  }

  resolveFormForNestedComponent(field: any): AbstractControl {
    return field.type === ContainerComponent ? this.form.controls[field.name] : this.form;
  }

  private formatDates(origin) {
    const values = Object.assign({}, origin);
    Object.entries(values).forEach(([key, value]) => {
      if (value instanceof moment) {
        // @ts-ignore
        values[key] = <moment>value.format('YYYY-MM-DD');
      } else if (value === null) {
        delete values[key];
      } else if (typeof value === 'object') {
        let isEmpty = true;
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (subValue.length !== 0) {
            isEmpty = false;
          } else {
            delete values[key][subKey];
          }
        });
        if (isEmpty) {
          delete values[key];
        }
      }
    });
    return values;
  }
}
