import {Component, OnInit} from '@angular/core';
import {FieldConfig} from '../dynamic-field';
import {ContainerAbstract} from './container.abstract';

@Component({
  selector: 'app-container',
  template: `
               <ng-container *ngFor="let item of items;">
                 <div class="row">
                   <ng-container *ngFor="let field of item;">
                     <div class="col">
                       <ng-container appDynamicField [field]="field" [group]="group">
                       </ng-container>
                     </div>
                   </ng-container>
                 </div>
               </ng-container>
             `,
  styles: []
})
export class ContainerComponent extends ContainerAbstract implements OnInit {
  name: string;
  items: FieldConfig[][] = [[]];
  type = ContainerComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}

