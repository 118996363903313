import {APP_BASE_HREF} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from 'app/@core/core.module';
import {UserService} from 'app/@core/utils/user.service';
import {ThemeModule} from 'app/@theme/theme.module';
import {AppRoutingModule} from 'app/app-routing.module';

import {AppComponent} from 'app/app.component';
import {MainAuthGuard, RoleAuthGuard} from 'app/main-auth-guard.service';
import {AlertService} from 'app/service/alert.service';
import {BackofficeService} from 'app/service/backoffice.service';
import {BpmnService} from 'app/service/bpmn.service';
import {DADService} from 'app/service/dad.service';
import {FileUploadService} from 'app/service/file-upload.service';
import {FormattingService} from 'app/service/formatting.service';
import {OrderService} from 'app/service/order.service';
import {SapService} from 'app/service/sap.service';
import {CachingInterceptor} from 'app/ui/helpers/caching.interceptor';
import {ErrorInterceptor} from 'app/ui/helpers/error.interceptor';
import {JwtInterceptor} from 'app/ui/helpers/jwt.interceptor';
import {RequestCacheService} from 'app/ui/helpers/request-cache.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    ThemeModule.forRoot(),
    CoreModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    RequestCacheService,
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    MainAuthGuard, RoleAuthGuard,
    OrderService, DADService, BpmnService, SapService, AlertService,
    BackofficeService, FileUploadService, FormattingService,
    {provide: APP_BASE_HREF, useValue: '/'},
    /*{provide: Ng2SmartTableComponent, useClass: SmartTableComponent},*/
    UserService
  ],
  entryComponents: []
})
export class AppModule {
}
