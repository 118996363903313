import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NbAuthToken} from '@nebular/auth';
import {SMLAuthService} from 'app/@core/utils/sml-auth.service';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: SMLAuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(
      switchMap((token: NbAuthToken) => {
        const JWT = `Bearer ${token.getValue()}`;
        request = request.clone({
          setHeaders: {
            Authorization: JWT
          }
        });
        return next.handle(request);
      })
    );
  }
}
