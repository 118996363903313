import {Component, OnInit} from '@angular/core';

import {NbMenuService, NbSidebarService} from '@nebular/theme';
import {Subscription} from 'rxjs';
import {AnalyticsService, LayoutService} from 'app/@core/utils';
import {UserService} from 'app/@core/utils/user.service';
import {subscribedContainerMixin} from 'app/ui/helpers/subscribed-container';
import {SampleLayoutComponent} from 'app/@theme/layouts';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent extends subscribedContainerMixin() implements OnInit {

  user: {
    displayName: string,
    email?: string,
    title?: string
  } = {displayName: 'dummy'};

  userMenu = [/*{title: 'Profile'}, */{title: 'Log out', link: '/auth/logout'}];
  private subscriptions: Subscription[] = [];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private analyticsService: AnalyticsService,
              private layoutService: LayoutService,
              private userService: UserService) {
    super();
    this.pipeSubscription(userService.getAuthenticatedUserInfo())
    .subscribe(user => this.user = user);
    SampleLayoutComponent.addCloseSideBarAction(this.menuService, this.sidebarService);
  }

  ngOnInit() {
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}
