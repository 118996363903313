import {Component, OnDestroy, ViewChild} from '@angular/core';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarComponent,
  NbSidebarService,
  NbThemeService
} from '@nebular/theme';

import {StateService} from 'app/@core/utils';
import {delay, takeWhile, withLatestFrom} from 'rxjs/operators';

// TODO: move layouts into the framework
@Component({
  selector: 'app-sample-layout',
  styleUrls: ['./sample.layout.scss'],
  template: `
    <nb-layout>
      <nb-layout-header style="background-color: #1A103F;" class="mat-elevation-z3" subheader>
        <app-header (click)="closeOpenMenu($event)"></app-header>
      </nb-layout-header>
      <nb-sidebar class="menu-sidebar"
                  tag="menu-sidebar"
                  state="collapsed"
                  fixed>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="main-content" (click)="closeOpenMenu($event)">
        <back-button></back-button>
        <feedback-button></feedback-button>
        <app-alert></app-alert>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <ng-container *ngIf="showFooter">
        <nb-layout-footer fixed>
          <ngx-footer></ngx-footer>
        </nb-layout-footer>
      </ng-container>
    </nb-layout>
  `
})
export class SampleLayoutComponent implements OnDestroy {

  layout: any = {};
  sidebar: any = {};
  currentTheme: string;
  showFooter = false;
  @ViewChild(NbSidebarComponent) nbSidebar: NbSidebarComponent;
  private alive = true;

  constructor(protected stateService: StateService,
              protected menuService: NbMenuService,
              protected themeService: NbThemeService,
              protected bpService: NbMediaBreakpointsService,
              protected sidebarService: NbSidebarService) {
    this.stateService.onLayoutState()
    .pipe(takeWhile(() => this.alive))
    .subscribe((layout: string) => this.layout = layout);

    this.stateService.onSidebarState()
    .pipe(takeWhile(() => this.alive))
    .subscribe((sidebar: string) => {
      this.sidebar = sidebar;
    });

    const isBp = this.bpService.getByName('is');
    this.menuService.onItemSelect()
    .pipe(
      takeWhile(() => this.alive),
      withLatestFrom(this.themeService.onMediaQueryChange()),
      delay(20)
    )
    .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
      if (bpTo.width <= isBp.width) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });

    this.themeService.getJsTheme()
    .pipe(takeWhile(() => this.alive))
    .subscribe(theme => {
      this.currentTheme = theme.name;
    });

    SampleLayoutComponent.addCloseSideBarAction(this.menuService, this.sidebarService);
  }

  static addCloseSideBarAction(menuService: NbMenuService, sidebarService: NbSidebarService) {
    // TODO Extract MENU init to dedicated service supported with injection
    menuService.onItemClick().subscribe(event => {
      const item: NbMenuItem = event.item;
      if (typeof item.data !== 'undefined'
        && item.data === 'collapseMenu') {
        sidebarService.collapse('menu-sidebar');
      } else {
        setTimeout(() => sidebarService.collapse('menu-sidebar'), 150);
      }
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  closeOpenMenu(event: MouseEvent) {
    let isClickOnNavigation = false;
    event.composedPath().forEach((element: Element) => {
      if (element.className === 'navigation' && element.tagName === 'A') {
        isClickOnNavigation = true;
        return;
      }
    });
    if (this.nbSidebar !== undefined && !!this.nbSidebar.expanded
      && !isClickOnNavigation) {
      this.nbSidebar.collapse();
    }
  }
}
