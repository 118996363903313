import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ContractOrderDto} from 'app/domain/dto/contract.interface';
import {DashboardStatistics, DashboardStatisticsDataType} from 'app/domain/dto/dashboard.interface';
import {DeRegistrationFileDto} from 'app/domain/dto/de-registration-import.interface';
import {
  CreatedShipmentDocumentDto,
  DeRegistrationDocumentDto,
  DeRegistrationIncident,
  DeRegistrationOrderDto,
  ShipmentDto
} from 'app/domain/dto/de-registration.interface';
import {IncidentStatus} from 'app/domain/dto/de-registration.interface';
import {ExternalSystem} from 'app/domain/dto/service-package.interface';
import {DeRegistrationSearchType} from 'app/modules/de-registration/vendor/create-shipment/create-shipment-search/de-registration-create-shipment-search.component';
import {
  convertToSortParams,
  createQueryParams,
  PageableRequestParameters
} from 'app/ui/helpers/request.helper';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';
import {Pageable} from '../domain/dto';
import {
  DeRegistrationSearch,
  DeRegistrationShipment
} from '../domain/dto/de-registration.interface';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable({providedIn: 'root'})
export class OrderService {

  baseUrl = environment.baseUrl;
  orderUrlPrefix = environment.orderServicePrefix;
  apiVersion = environment.apiVersion;

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
  }

  sendDeRegistrationOrderIncidentMessage(id: string, details: any): Observable<any> {
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + `/de-registration/orders/${id}/incident`;

    return this.http.post(url, details, httpOptions);
  }

  getContractOrderById(id: string): Observable<ContractOrderDto> {
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + '/contracts/order/' + id;
    return this.http.get<ContractOrderDto>(url, httpOptions);
  }

  getShipmentOrders(): Observable<DeRegistrationShipment[]> {
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + '/de-registration/shipments/current';

    return this.http.get<DeRegistrationShipment[]>(url, httpOptions);
  }

  deleteShipmentOrder(id: string) {
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + `/de-registration/shipments/current/orders/${id}`;

    return this.http.delete(url, httpOptions);
  }

  getDeRegistrationShipmentDocuments(queryParams = {}): Observable<Pageable<DeRegistrationDocumentDto>> {
    const params = Object.keys(queryParams).length > 0 ? createQueryParams(convertToSortParams(queryParams)) : '';
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + '/de-registration/documents' + '?' + params;

    return this.http.get<Pageable<DeRegistrationDocumentDto>>(url, httpOptions);
  }

  downloadDeRegistrationShipmentPdf(id: string): Observable<HttpResponse<BlobPart>> {
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + `/de-registration/documents/${id}`;
    const options = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    };

    return this.http.get<HttpResponse<BlobPart>>(url, options);
  }

  downloadPdfFromResponse = (response: HttpResponse<BlobPart>, fileName?: string): void => {
    const {body: data} = response;

    const contentDisposition = response.headers.get('content-disposition');
    let reportFileName;
    
    if (fileName){
      reportFileName = fileName;
    } else if (contentDisposition) {
      reportFileName =contentDisposition.split('filename')[1].split('=')[1].trim().slice(1, -1);
    } else {
      reportFileName = 'report.pdf'
    }

    const blob = new Blob([data], {type: 'application/pdf'});
    const downloadLink = document.createElement('a');

    downloadLink.href = window.URL.createObjectURL(blob);
    document.body.appendChild(downloadLink);
    downloadLink.download = reportFileName;
    downloadLink.click();
  }

  shipDeRegistrationShipments(): Observable<CreatedShipmentDocumentDto> {
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + '/de-registration/shipments/current/ship';

    return this.http.post<CreatedShipmentDocumentDto>(url, null, httpOptions);
  }

  searchDeRegistrationOrders(type: DeRegistrationSearchType,
                             value: string,
                             parameters: PageableRequestParameters): Observable<Pageable<DeRegistrationSearch>> {
    const params = createQueryParams(convertToSortParams(parameters));
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + `/de-registration/orders/search?type=${type}&value=${value}&${params}`;

    return this.http.get<Pageable<DeRegistrationSearch>>(url, httpOptions);
  }

  createDeRegistrationShipment(dto: ShipmentDto): Observable<any> {
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + `/de-registration/shipments/current/orders`;

    return this.http.post<any>(url, dto, httpOptions);
  }

  uploadDeRegistrationFile(date: string, system: ExternalSystem, file: Blob): Observable<any> {
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + `/de-registration/orders/upload`;

    const formData: FormData = new FormData();

    formData.append('date', date);
    formData.append('system', system);
    formData.append('file', file);

    return this.http.post<any>(url, formData);
  }

  getDeRegistrationImportHistory(parameters: string): Observable<Pageable<DeRegistrationFileDto>> {
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + '/de-registration/orders/upload/history?';

    return this.http.get<Pageable<DeRegistrationFileDto>>(url + parameters, httpOptions);
  }

  getDeRegistrationOrders(tabType: string, parameters: PageableRequestParameters): Observable<Pageable<DeRegistrationOrderDto>> {
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + `/de-registration/orders?`;
    const params = createQueryParams(convertToSortParams(parameters));

    return this.http.get<Pageable<DeRegistrationOrderDto>>(url + params + `&filter=${tabType}`, httpOptions);
  }

  getDeRegistrationOrderById(id: string): Observable<DeRegistrationOrderDto> {
    const url = `${this.baseUrl}${this.orderUrlPrefix}${this.apiVersion}/de-registration/orders/${id}`;

    return this.http.get<DeRegistrationOrderDto>(url, httpOptions);
  }

  getDeRegistrationOrderHistory(id: string): Observable<DeRegistrationOrderDto[]> {
    const url = `${this.baseUrl}${this.orderUrlPrefix}${this.apiVersion}/de-registration/orders/${id}/history`;

    return this.http.get<DeRegistrationOrderDto[]>(url, httpOptions);
  }

  getDeRegistrationOrderIncidents(type: IncidentStatus, filter: string, parameters = {}): Observable<Pageable<DeRegistrationIncident>> {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + this.orderUrlPrefix + this.apiVersion + `/de-registration/orders/incidents?${params ? (params + '&') : params}type=${type}` + (filter ? `&filter=${filter}` : '');

    return this.http.get<Pageable<DeRegistrationIncident>>(url, httpOptions);
  }

  cancelDeRegistrationOrders(id: string, reason) {
    const url = `${this.baseUrl}${this.orderUrlPrefix}${this.apiVersion}/de-registration/orders/${id}?reason=${encodeURIComponent(reason)}`;

    return this.http.delete(url, httpOptions);
  }

  getDashboardData(dataType: DashboardStatisticsDataType): Observable<DashboardStatistics> {
    const url = `${this.baseUrl}${this.orderUrlPrefix}${this.apiVersion}/de-registration/orders/dashboard?dataType=${dataType}`;

    return this.http.get<DashboardStatistics>(url);
  }

}
