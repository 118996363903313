import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {BackofficeService} from 'app/service/backoffice.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: 'search-bar.component.html',
  styleUrls: ['search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {

  searchType: ('CONTRACT_ID' | 'LICENSE_PLATE' | 'VIN' | 'CUSTOMER_NAME');
  searchInput: string;

  private subscriptions: Subscription[] = [];

  constructor(private service: BackofficeService,
              private router: Router,
              private route: ActivatedRoute) {

  }

  onSearch(form: NgForm) {
    if (form.valid && form.value.searchType !== undefined) {
      const value = form.value;
      this.router.navigate(['/search'], {
        queryParams: {
          searchType: value.searchType,
          query: encodeURIComponent(value.searchInput)
        }
      });
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParamMap
      .subscribe((params: ParamMap) => {
        // @ts-ignore
        this.searchType = params.get('searchType') !== null ? params.get('searchType') : undefined;
        this.searchInput = params.get('query') !== null ? decodeURIComponent(params.get('query')) : undefined;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit(): void {
  }
}
