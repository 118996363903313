import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';

@Pipe({name: 'german_timezone'})
export class GermanTimezonePipe implements PipeTransform {
  transform(date: string | number | moment.Moment | Date, format: string, inputFormat?: string[]) {
    if (!date) {
      return ' – ';
    }

    if (inputFormat !== undefined) {
      date = moment.utc(date, inputFormat).format();
    } else {
      date = moment.utc(date).format();
    }

    if (date) {
      return momentTZ.tz(date, 'Europe/Berlin').format(format);
    }

    return ' – ';
  }
}
