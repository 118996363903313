import {OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export type Constructor<T = {}> = new (...args: any[]) => T;

export const subscribedContainerMixin = <T extends Constructor>(base: T = class {
} as T) =>
  class extends base implements OnDestroy {
    destroyed$ = new Subject<boolean>();

    pipeSubscription<P>(observable: Observable<P>): Observable<P> {
      return observable.pipe(takeUntil(this.destroyed$));
    }

    ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  };
