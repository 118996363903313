import {Component, OnInit} from '@angular/core';
import {CheckboxAbstract} from './checkbox.interface';

@Component({
  selector: 'app-checkbox',
  template: `
    <div class="container-fluid mt-3" [formGroup]="group">
      <mat-checkbox [formControlName]="name" [checked]="checked">{{label}}</mat-checkbox>
      <mat-hint></mat-hint>
      <!--<ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>-->
    </div>
  `,
  styles: []
})
export class CheckboxComponent extends CheckboxAbstract implements OnInit {
  type = CheckboxComponent;
  name: string;
  label: string;
  checked = false;
  disabled = false;

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
