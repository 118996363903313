import {Type} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../dynamic-field';

export abstract class CardAbstract implements FieldConfig {
  abstract type: Type<CardAbstract>;
  group: FormGroup;
  abstract title: string;
  abstract description: string;
  abstract childItems: FieldConfig[];
  abstract isExpanded?: boolean;
}
