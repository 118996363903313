import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {StoredFile} from 'app/domain/dto/file.interface';
import {environment} from 'environments/environment';
import {Observable, Subscription} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FileUploadService implements OnDestroy {
  baseUrl = environment.baseUrl;

  private subscriptions: Subscription[] = [];

  constructor(private http: HttpClient) {
  }

  getFileLink(file: StoredFile, cb: (url: string) => void): void {
    const url = this.baseUrl + `/api-gateway/files/${file.fileName}`;

    this.subscriptions.push(
      this.http.get(url, {responseType: 'arraybuffer'})
      .subscribe(content => {
        const outputFile = new Blob([content], {type: file.contentType});
        const fileUrl = URL.createObjectURL(outputFile);

        cb(fileUrl);
      })
    );
  }

  getContractFiles(contractId: string): Observable<StoredFile[]> {
    const url = this.baseUrl + `/api-gateway/files?contractId=${contractId}`;

    return this.http.get<StoredFile[]>(url);
  }

  getContractFilesPromoted(contractId: string): Observable<StoredFile[]> {
    const url = this.baseUrl + `/api-gateway/files/promoted?contractId=${contractId}`;

    return this.http.get<StoredFile[]>(url);
  }

  sendFile(id: string, file: File): Observable<File> {
    const formData = new FormData();
    const url = this.baseUrl + '/api-gateway/files';

    formData.append('contractId', id);
    formData.append('file', file);

    return this.http.post<File>(url, formData);
  }

  removeFile(name: string) {
    const url = this.baseUrl + `/api-gateway/files/${name}`;

    return this.http.delete<any>(url);
  }

  updatePricing(file: File): Observable<File> {
    const formData = new FormData();
    const url = this.baseUrl + '/api-gateway/sap/import';

    formData.append('file', file);

    return this.http.post<File>(url, formData);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
