<div class="d-flex justify-content-center">
  <form #form="ngForm" (ngSubmit)="onSearch(form)">
    <mat-form-field>
      <mat-label>SEARCH TYPE</mat-label>
      <mat-select [(ngModel)]="searchType"
                  [(value)]="searchType"
                  class="search-select"
                  name="searchType"
                  ngModel>
        <mat-option value="CONTRACT_ID">LVID</mat-option>
        <mat-option value="LICENSE_PLATE">LICENСE PLATE NUMBER</mat-option>
        <mat-option value="VIN">VIN</mat-option>
        <mat-option value="CUSTOMER_NAME">CUSTOMER NAME</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="container-fluid mt-auto search-input">
      <input [(ngModel)]="searchInput"
             fullWidth
             matInput
             minlength="3"
             name="searchInput"
             ngModel
             placeholder="SEARCH FOR..."
      >
    </mat-form-field>
    <nb-icon (click)="onSearch(form)" class="mt-2"
             icon="search-outline"
             style="font-size: 1.7rem;margin-left: -2.3rem;cursor: pointer;"
    ></nb-icon>
  </form>
</div>
