import {Component, OnInit} from '@angular/core';
import {Validator} from '../dynamic-field';
import {DateAbstract} from './date.abstract';

@Component({
  selector: 'app-date',
  template: `
    <mat-form-field class="container-fluid mt-auto" [formGroup]="group">
      <input matInput [matDatepicker]="picker" [formControlName]="name" [placeholder]="label">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-hint></mat-hint>
      <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group.get(name).hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styles: []
})
export class DateComponent extends DateAbstract implements OnInit {
  type = DateComponent;
  name: string;
  label: string;
  validations: Validator[] = [];

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
