import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SortDirection} from '@angular/material/sort';
import {Pageable} from 'app/domain/dto';
import {MaterialPriceExceptionDto} from 'app/domain/dto/sap-material-price-exception.interface';
import {MaterialDto} from 'app/domain/dto/sap-material.interface';
import {ServicePackageDto, VendorDto} from 'app/domain/dto/service-package.interface';
import {environment} from 'environments/environment';
import {forkJoin, Observable} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable(
  {
    providedIn: 'root'
  }
)
export class SapService {
  baseUrl = environment.baseUrl;
  sapUrlPrefix = environment.sapServicePrefix;
  apiVersion = environment.apiVersion;

  constructor(private http: HttpClient) {
  }

  getServicePackages(pageIndex: number = 0,
                     pageSize: number = 0,
                     sortActive: string = 'id',
                     sortDirection: SortDirection = 'asc'): Observable<Pageable<ServicePackageDto>> {
    let url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/sp?page=${pageIndex}&size=${pageSize}`;
    if (sortActive.length > 0) {
      url += `&sort=${sortActive},`;
    }
    if (sortDirection.length > 0) {
      url += `${sortDirection}`;
    }

    return this.http.get<Pageable<ServicePackageDto>>(url, httpOptions);
  }

  unbindServicePackage(id: number, vendorId: number) {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/sp/${id}/bind`;

    return this.http.request('delete', url, {
      body: {
        vendorId
      },
      ...httpOptions
    });
  }

  getMaterials(pageIndex: number = 0,
               pageSize: number = 0,
               sortActive: string = 'materialId',
               sortDirection: SortDirection = 'desc'): Observable<Pageable<MaterialDto>> {
    let url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/materials?page=${pageIndex}&size=${pageSize}`;
    if (sortActive.length > 0) {
      url += `&sort=${sortActive},`;
    }
    if (sortDirection.length > 0) {
      url += `${sortDirection}`;
    }

    return this.http.get<Pageable<MaterialDto>>(url, httpOptions);
  }

  bindVendorToServicePackage(data: {package: number; vendor: number}): Observable<null> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/sp/${data.package}/bind`;
    return this.http.post<null>(url, {
      vendorId: data.vendor
    }, httpOptions);
  }

  getMaterialPriceExceptions(materialId: number | string): Observable<MaterialPriceExceptionDto[]> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/prices?materialId=${materialId}`;
    return this.http.get<MaterialPriceExceptionDto[]>(url, httpOptions);
  }

  getAllMaterials(pageSize: number = 1000): Observable<Pageable<MaterialDto>> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/materials?size=${pageSize}`;
    return this.http.get<Pageable<MaterialDto>>(url, httpOptions);
  }

  addMaterial(dto: MaterialDto): Observable<MaterialDto> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/materials`;
    return this.http.post<MaterialDto>(url, dto, httpOptions);
  }

  updateMaterial(dto: MaterialDto): Observable<MaterialDto> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/materials/${dto.id}`;
    return this.http.put<MaterialDto>(url, dto, httpOptions);
  }

  removeMaterial(materialId: number): Observable<null> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/materials/${materialId}`;
    return this.http.delete<null>(url, httpOptions);
  }

  addPrices(prices: MaterialPriceExceptionDto[]): Observable<MaterialPriceExceptionDto[]> {
    const requests = prices.map((price: MaterialPriceExceptionDto) => this.addPrice(price));
    return forkJoin(requests);
  }

  addPrice(price: MaterialPriceExceptionDto): Observable<MaterialPriceExceptionDto> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/price`;
    return this.http.post<MaterialPriceExceptionDto>(url, price, httpOptions);
  }

  updatePrices(prices: MaterialPriceExceptionDto[]): Observable<MaterialPriceExceptionDto[]> {
    const requests = prices.map((price: MaterialPriceExceptionDto) => this.updatePrice(price));
    return forkJoin(requests);
  }

  updatePrice(price: MaterialPriceExceptionDto): Observable<MaterialPriceExceptionDto> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/price/${price.id}`;
    return this.http.patch<MaterialPriceExceptionDto>(url, price, httpOptions);
  }

  removePrices(prices: MaterialPriceExceptionDto[]): Observable<null[]> {
    const requests = prices.map((price: MaterialPriceExceptionDto) => this.removePrice(price.id));
    return forkJoin(requests);
  }

  removePrice(priceId: string | number): Observable<null> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/price/${priceId}`;
    return this.http.delete<null>(url, httpOptions);
  }

  getAllPriceExceptions(pageSize: number = 10000): Observable<Pageable<MaterialPriceExceptionDto>> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/prices?size=${pageSize}`;
    return this.http.get<Pageable<MaterialPriceExceptionDto>>(url, httpOptions);
  }

  getVendorsByServicePackage(id: number): Observable<VendorDto[]> {
    const url = this.baseUrl + this.sapUrlPrefix + this.apiVersion + `/sap/sp/${id}/vendors`;

    return this.http.get<VendorDto[]>(url, httpOptions);
  }

  getVendorsForDeFleetTransport(): Observable<VendorDto[]> {
    return this.getVendorsByServicePackage(5000);
  }

}
