import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {
  NbAuthModule,
  NbAuthService,
  NbPasswordAuthStrategy,
  NbTokenLocalStorage,
  NbTokenStorage
} from '@nebular/auth';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {SMLAuthService} from 'app/@core/utils/sml-auth.service';
import {environment} from 'environments/environment';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {AnalyticsService, ExternalAuthToken, LayoutService, StateService} from './utils';
import {AuthADFSToken, InternalAuthStrategy} from './utils/internal-auth.strategy';
import {SMLRoleProvider} from './utils/sml-role.service';

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'external',
        token: {
          class: ExternalAuthToken,
          key: 'token'
        },
        baseEndpoint: environment.baseUrl,
        login: {
          endpoint: '/api/auth/login',
          method: 'post'
        },
        logout: {
          endpoint: '/api/auth/logout',
          method: 'post'
        },
        refreshToken: {
          endpoint: '/api/auth/token',
          method: 'get'
        },
        resetPass: {
          endpoint: '/api/auth/password',
          method: 'delete',
          resetPasswordTokenKey: 'token'
        }
      }),
      InternalAuthStrategy.setup({
        name: 'internal',
        clientId: environment.clientId,
        clientSecret: '',
        authorize: {
          endpoint: environment.fedService,
          responseType: environment.responseType,
          scope: environment.authorizeScope,
          redirectUri: environment.redirectUrl
        },
        refresh: {
          endpoint: environment.baseUrl + '/api/auth/token',
          requireValidToken: false
        },
        token: {
          class: AuthADFSToken,
          endpoint: environment.baseUrl + '/api/auth/fed'
        },
        redirect: {
          success: '/pages/dashboard',
          failure: '/auth/login'
        }
      })
    ],
    forms: {
      validation: {
        password: {
          required: true,
          minLength: 3,
          maxLength: 12
        },
        email: {
          required: true
        }
      }
    }
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      vendor: {
        view: ['userProfileMenu', 'externalMenu'],
        url: [
          /*'/pages/dashboard',*/
          '/transport/vendor',
          '/transport/vendor/order/*',
          '/transport/invoicing',
          '/search',
          '/de-registration/vendor',
          '/de-registration/vendor/*',
          '/de-registration/vendor/shipments',
          '/de-registration/vendor/create-shipment',
          '/de-registration/vendor/order/*',
          '/appointments/calendar',
          '/appointments/*',
          '/fleet/in/order/details/',
          '/fleet/in/order/details/*',
          '/fleet/de/order/details/',
          '/fleet/de/order/details/*',
          '/fleet',
          '/fleet/*',
          '/fleet/de',
          '/fleet/de/*',
          '/fleet/de/request-order',
          '/fleet/in',
          '/fleet/in/*',
          '/fleet/in/request-order',
          '/fleet-transports',
          '/fleet-transports/*',
          '/fleet-transports/vendor',
          '/fleet-transports/vendor/*',
          '/fleet-transports/vendor/orders',
          '/fleet-transports/vendor/orders/*',
          '/fleet-transports/vendor/order/*',
          '/fleet-transports/vendor/order/details',
          '/fleet-transports/vendor/order/details/*'
        ]
      },
      agent: {
        view: ['userProfileMenu', 'agentMenu', 'sap'],
        url: [
          '/pages/dashboard',
          '/search',
          '/dad-warnings',
          '/dad-responses',
          '/contracts',
          '/contracts/order/*',
          '/registrations/order',
          '/registrations/order/sixt/*',
          '/registrations/order/customer/*',
          '/registrations/task',
          '/registrations/task/details/*',
          '/sap/order/*',
          '/transport/driver',
          '/transport/driver/order/*',
          '/de-registration/internal/order',
          '/de-registration/internal/order/*',
          '/de-registration/internal/task',
          '/de-registration/internal/task/details/*',
          '/de-registration/internal/import',
          '/de-registration/internal/shipment-list',
          '/de-registration/internal/report',
          '/fleet/*',
          '/fleet/warnings',
          '/fleet/warnings/*',
          '/fleet/integration-warnings',
          '/fleet/integration-warnings/*',
          '/fleet/in/order/details/',
          '/fleet/in/order/details/*',
          '/fleet/in/dealer',
          '/fleet/in/dealer/*',
          '/fleet/in/dealer/orders',
          '/fleet/in/orders',
          '/fleet/in/orders/*',
          '/fleet/de/order/details/',
          '/fleet/de/order/details/*',
          '/fleet/de/orders',
          '/fleet/de/orders/*',
          '/fleet/de',
          '/fleet/de/*',
          '/fleet/de/request-order',
          '/fleet/in',
          '/fleet/in/*',
          '/fleet/in/request-order',
          '/appointments/calendar',
          '/appointments/*',
          '/fleet-transports',
          '/fleet-transports/*',
          '/fleet-transports/internal',
          '/fleet-transports/internal/*',
          '/fleet-transports/internal/report',
          '/fleet-transports/internal/report/*',
          '/fleet-transports/internal/orders',
          '/fleet-transports/internal/orders/*',
          '/fleet-transports/internal/order/*',
          '/fleet-transports/internal/order/details',
          '/fleet-transports/internal/order/details/*',
        ]
      },
      teamLead: {
        parent: 'agent',
        view: ['teamLeadMenu'],
        edit: ['userDisplayConfiguration'],
        url: [
          '/user-management/history',
          '/user-management/history/*',
          '/user-management/internal',
          '/user-management/internal/edit/*',
          '/user-management/external',
          '/user-management/external/edit/*',
          '/supplier-management',
          '/supplier-management/vendor',
          '/supplier-management/vendor/edit/*',
          '/supplier-management/vendor/add',
          '/supplier-management/sp/edit/*',
          '/supplier-management/material/edit/*',
          '/supplier-management/material/add',
          '/supplier-management/locations/*',
          '/supplier-management/locations/add',
          '/supplier-management/locations/edit',
          '/supplier-management/locations/edit/*',
        ]
      },
      manager: {
        parent: 'teamLead',
        view: ['adminMenu'],
        url: []
      },
      admin: {
        parent: 'manager',
        url: [
          '/resources-management',
          '/resources-management/*',
          '/resources-management/appointment',
          '/resources-management/human',
          '/resources-management/appointment/*',
          '/resources-management/human/*',
          '/resources-management/appointment/add',
          '/resources-management/human/add',
          '/resources-management/appointment/edit',
          '/resources-management/human/edit',
          '/resources-management/appointment/edit/*',
          '/resources-management/human/edit/*',
          '/resources-management/appointment/add/*',
          '/resources-management/human/add/*'
        ]
      }
    }
  }).providers,
  {
    provide: NbTokenStorage, useClass: NbTokenLocalStorage
  },
  {
    provide: NbRoleProvider, useClass: SMLRoleProvider
  },
  {
    provide: NbAuthService, useExisting: SMLAuthService
  },
  AnalyticsService,
  LayoutService,
  StateService,
  InternalAuthStrategy
];

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    NbAuthModule
  ],
  declarations: [],
  providers: [SMLAuthService, SMLRoleProvider]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS
      ]
    };
  }
}
