import {Type} from '@angular/core';
import {FormGroup} from '@angular/forms';

export interface Validator {
  name: string;
  validator: any;
  message: string;
}

export interface FieldConfig {
  type: Type<FieldConfig>;
  group: FormGroup;
}

/*export interface FieldConfig {
 label?: string;
 name?: string;
 inputType?: string;
 options?: any[];
 collections?: any;
 type: string;
 value?: any;
 validations?: Validator[];
 minLength?: number;
 maxLength?: number;
 group?: string;
 formGroup?: string;
 parentGroup?: string;
 }*/
