import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {NbAccessChecker} from '@nebular/security';
import {ExternalAuthToken} from 'app/@core/utils';
import {AuthADFSToken} from 'app/@core/utils/internal-auth.strategy';
import {SMLAuthService} from 'app/@core/utils/sml-auth.service';
import {BackendPermissionMenuMap} from 'app/pages/pages-menu';
import {collectPathParams, collectQueryParams} from 'app/ui/helpers/request.helper';
import {Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Injectable()
export class MainAuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: SMLAuthService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isAuthenticatedOrRefresh(state, next)
    .pipe(
      tap(authenticated => {
        if (!authenticated) {
          const url = state.url.split('?')[0];
          const params = collectQueryParams(next);
          this.router.navigate(['auth/login'], {
            queryParams: {
              from: encodeURIComponent(url),
              params: encodeURIComponent(JSON.stringify(Object.fromEntries(params)))
            }
          });
          return false;
        }
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    let url = state.url.split('?')[0];
    return this.authService.getToken()
    .pipe(
      switchMap((token: (AuthADFSToken | ExternalAuthToken)) => {
        if (url.includes('dashboard') && token.getOwnerStrategyName() !== 'internal') {
          const parsedToken = token.getParsedToken();
          if (parsedToken.permissions.menu.includes('deRegistrationVendorCreateShipment')) {
            url = BackendPermissionMenuMap.deRegistrationVendorCreateShipment;
          } else if (parsedToken.permissions.menu.includes('fleetTransportExternal')) {
            url = BackendPermissionMenuMap.fleetTransportExternal;
          } else if (parsedToken.permissions.menu.includes('appointments')) {
            url = BackendPermissionMenuMap.appointments;
          } else if (parsedToken.permissions.menu.length === 0) {
            url = '/pages/404';
          }
          this.router.navigate([url]);
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }
}

@Injectable()
export class RoleAuthGuard implements CanActivate {
  constructor(private authService: SMLAuthService,
              private router: Router,
              private accessChecker: NbAccessChecker) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    let url = state.url;
    const params: Map<string, string> = collectPathParams(next);
    params.forEach(paramValue => {
      url = url.replace(paramValue, '*');
    });
    url = url.split('?')[0];
    return this.accessChecker.isGranted('url', url)
    .pipe(
      tap(isGranted => {
        if (!isGranted) {
          this.router.navigate(['pages/404']);
          return false;
        }
        return true;
      })
    );
  }
}

