import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {NbDialogService} from '@nebular/theme';
import {DeRegistrationFileDto} from 'app/domain/dto/de-registration-import.interface';
import {subscribedContainerMixin} from 'app/ui/helpers/subscribed-container';

export interface Error {
  id: number;
  message: string;
}

@Component({
  selector: 'app-import-file-history-error-dialog',
  templateUrl: './import-file-history-error-dialog.component.html'
})
export class ImportFileHistoryErrorDialogComponent extends subscribedContainerMixin() implements OnInit, OnDestroy {

  @Input() trigger: EventEmitter<number> = new EventEmitter<number>();
  @Input() fileHistory: any;
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dialog', {static: true}) dialog: TemplateRef<any>;
  dataSource: MatTableDataSource<Error> = new MatTableDataSource<Error>();
  size = 100;
  resultsLength = 0;

  constructor(private dialogService: NbDialogService) {
    super();
  }

  ngOnInit(): void {
    this.pipeSubscription(this.trigger)
    .subscribe((id: number) => {
      if (this.fileHistory.id === id) {
        this.dataSource.data = this.fileHistory.errors.map((v, index) => <Error>{
          message: v,
          id: index + 1
        });
        this.resultsLength = this.fileHistory.errors.length;
        this.open(this.dialog);
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  open(dialog: TemplateRef<any>) {
    const ref = this.dialogService.open(dialog);
    ref.onClose.subscribe(() => {
      this.closeEvent.emit();
    });
    ref.onBackdropClick.subscribe(() => {
      this.closeEvent.emit();
    });
  }
}
