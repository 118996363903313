import {HttpErrorResponse} from '@angular/common/http';
import {Component} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {FieldConfig} from '../dynamic-field';
import {SelectOptionInterface} from '../select';

@Component({
  selector: 'app-base-component',
  template: 'NO UI TO BE FOUND HERE!'
})
export class BaseComponent {
  static create(config: FieldConfig): FieldConfig {
    return Object.assign(new this(), config);
  }

  static enumToSelectOptionInterface(enumerated): SelectOptionInterface[] {
    const options: SelectOptionInterface[] = [];
    const keys = Object.keys(enumerated);
    for (const key of keys) {
      options.push(
        {
          title: key.replace(/_/g, ' '),
          value: enumerated[key]
        }
      );
    }
    return options;
  }

  static handleBackendValidationErrors(form: FormGroup, error: HttpErrorResponse): void {
    const constraints: [] = error.error.fieldConstraints;
    constraints.forEach((er: { fieldPath: string, rejectedValue: string, message: string }) => {
      const path = er.fieldPath.split('.');
      let el: FormGroup | AbstractControl = form;
      path.forEach((pathPart) => {
        if (el !== undefined) {
          el = el.get(pathPart);
        }
      });
      if (el !== undefined) {
        el.setErrors({'custom': er.message});
      }
    });
  }
}

