import {Injectable, OnDestroy} from '@angular/core';

import {NbLayoutDirection, NbLayoutDirectionService} from '@nebular/theme';
import {BehaviorSubject, Observable, of as observableOf} from 'rxjs';
import {takeWhile} from 'rxjs/operators';

@Injectable()
export class StateService implements OnDestroy {

  alive = true;
  protected layouts: any = [
    {
      name: 'One Column',
      icon: 'layout-outline',
      id: 'one-column',
      selected: true
    }
  ];
  protected sidebars: any = [
    {
      name: 'Sidebar at layout start',
      icon: 'layout-outline',
      id: 'start',
      selected: true
    }
  ];
  protected layoutState$ = new BehaviorSubject(this.layouts[0]);
  protected sidebarState$ = new BehaviorSubject(this.sidebars[0]);

  constructor(directionService: NbLayoutDirectionService) {
    directionService.onDirectionChange()
    .pipe(takeWhile(() => this.alive))
    .subscribe(direction => this.updateSidebarIcons(direction));

    this.updateSidebarIcons(directionService.getDirection());
  }

  ngOnDestroy() {
    this.alive = false;
  }

  setLayoutState(state: any): any {
    this.layoutState$.next(state);
  }

  getLayoutStates(): Observable<any[]> {
    return observableOf(this.layouts);
  }

  onLayoutState(): Observable<any> {
    return this.layoutState$.asObservable();
  }

  setSidebarState(state: any): any {
    this.sidebarState$.next(state);
  }

  getSidebarStates(): Observable<any[]> {
    return observableOf(this.sidebars);
  }

  onSidebarState(): Observable<any> {
    return this.sidebarState$.asObservable();
  }

  private updateSidebarIcons(direction: NbLayoutDirection) {
    const startSidebar = this.sidebars[0];
    startSidebar.icon = 'nb-layout-sidebar-left';
  }
}
