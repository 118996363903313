<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card style="max-height: 70vh; min-width: 360px; max-width: 80vw">
    <nb-card-header>Validation Errors ({{dataSource?.data?.length}})</nb-card-header>
    <nb-card-body class="pl-0 pr-0">
      <div class="container-fluid pl-0 pr-0">
        <div class="table-responsive table-container-background ">

          <div class="registrations-container container-fluid table-container-background pl-0 pr-0">
            <mat-table [dataSource]="dataSource" class="table table-striped table-hover">
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef class="m-sm-2 p-sm-2 table-static-col"> #
                </mat-header-cell>
                <mat-cell *matCellDef="let error" class="m-sm-2 p-sm-2 table-static-col"
                >{{error.id}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="error">
                <mat-header-cell *matHeaderCellDef class="m-sm-2 p-sm-2"> Message
                </mat-header-cell>
                <mat-cell *matCellDef="let error" class="m-sm-2 p-sm-2"
                >{{error.message}}</mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="['id','error']"></mat-header-row>
              <mat-row *matRowDef="let error; columns: ['id','error'];"></mat-row>
            </mat-table>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button (click)="ref.close()" class="btn btn-primary btn-sm float-right mr-4 rounded-0"
              color="primary"
              mat-raised-button
              type="button">CLOSE
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>
