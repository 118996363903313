import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {BehaviorSubject} from 'rxjs';
import {Validator} from '../dynamic-field';
import {SelectOptionInterface} from './select-option.interface';
import {SelectAbstractDirective} from './select.abstract';

@Component({
  selector: 'app-re-select',
  template: `
    <div class="position-relative">
      <mat-form-field class="container-fluid mt-auto">
        <mat-select [placeholder]="label"
                    [formControl]="control"
                    [compareWith]="compareObjects"
                    (selectionChange)="actionHappened($event)"
                    fullWidth
                    ngDefaultControl
        >
          <mat-option *ngFor="let item of options" [value]="item.value">{{item.title}}</mat-option>
        </mat-select>
        <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
          <mat-error
            *ngIf="group.get(name).hasError(validation.name)">{{validation.message}}</mat-error>
        </ng-container>
      </mat-form-field>
    </div>
  `,
  styles: []
})
export class ReusableSelectComponent extends SelectAbstractDirective implements OnInit, OnDestroy {
  @Input() optionsPromise?: BehaviorSubject<SelectOptionInterface[]> = new BehaviorSubject(null);
  @Input() control: FormControl;
  @Input() type = ReusableSelectComponent;
  @Input() label: string;
  @Input() name: string;
  @Input() options: SelectOptionInterface[] = [];
  @Input() validations: Validator[] = [];
  @Input() disabled = false;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.control = this.group.get(this.name) as FormControl;
    this.pipeSubscription(this.optionsPromise)
    .subscribe(data => {
      if (data) {
        this.options = data;
      }
    });
  }

  compareObjects(o1: any, o2: any): boolean {
    return (o1 !== null && o2 !== null) ? o1.toString() === o2.toString() : false;
  }

  ngOnDestroy() {
    this.selectionChange.complete();
    super.ngOnDestroy();
  }

  actionHappened($event: MatSelectChange) {
    this.selectionChange.emit($event);
  }
}
