import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray} from '@angular/forms';
import {FieldConfig} from '../dynamic-field';
import {ContainerAbstract} from './container.abstract';

@Component({
  selector: 'app-list-container',
  template: `
               <ng-container *ngFor="let item of items; let i = index">
                 <div class="row">
                   <div class="col">
                     <ng-container appDynamicField [field]="item" [group]="resolveFormForNestedComponent(item, i)">
                     </ng-container>
                   </div>
                 </div>
               </ng-container>
             `,
  styles: []
})
export class ListContainerComponent extends ContainerAbstract implements OnInit {
  name: string;
  items: FieldConfig[][] = [[]];
  type = ListContainerComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  resolveFormForNestedComponent(field: any, index: number): AbstractControl {
    return (<FormArray>this.group.controls[field.name.slice(0, -1)]);
  }
}

