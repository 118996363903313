import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {
  NbAuthOAuth2JWTToken,
  NbAuthRefreshableToken,
  NbAuthResult,
  NbAuthStrategyClass,
  NbOAuth2AuthStrategy,
  NbOAuth2AuthStrategyOptions,
  NbOAuth2ResponseType
} from '@nebular/auth';
import {Observable, of} from 'rxjs';

import {catchError, map, switchMap} from 'rxjs/operators';

export class AuthADFSToken extends NbAuthOAuth2JWTToken {
  static NAME = 'sml:auth:adfs:token';

  getValue(): string {
    return this.token.token;
  }

  isValid(): boolean {
    return super.isValid()
      && (!this.getTokenExpDate()
        || new Date().getTime() < (this.getTokenExpDate().getTime() - 1800000));
  }

  getParsedToken(): {
    exp: number,
    iat: number,
    permissions: {
      menu: string[],
    }
    roles: string,
    firstName: string,
    lastName: string,
    sub: string,
    vendorId: number,
    vendorName: string,
    location: string
  } {
    return this.getAccessTokenPayload();
  }
}

@Injectable()
export class InternalAuthStrategy extends NbOAuth2AuthStrategy {

  protected redirectResults: any = {
    [NbOAuth2ResponseType.CODE]: () => {
      return of(this.route.snapshot.queryParams).pipe(
        map((params: any) => !!(params && (params.code || params.error)))
      );
    }
  };

  protected redirectResultHandlers = {
    [NbOAuth2ResponseType.CODE]: () => {
      return of(this.route.snapshot.queryParams).pipe(
        switchMap((params: any) => {
          if (params.code && params.state) {
            const state = JSON.parse(atob(params.state));
            return this.requestJwtToken(params.code, state);
          }

          return of(
            new NbAuthResult(
              false,
              params,
              this.getOption('redirect.failure'),
              this.getOption('defaultErrors'),
              []
            ));
        })
      );
    }
  };

  static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [InternalAuthStrategy, options];
  }

  authenticate(data?: any): Observable<NbAuthResult> {
    this.options['authorize']['state'] = btoa(JSON.stringify(data));
    return super.authenticate(data);
  }

  refreshToken(token: NbAuthRefreshableToken): Observable<NbAuthResult> {
    const that = this;
    const module = 'refresh';
    const url = this.getOption(`${module}.endpoint`);
    const requireValidToken = this.getOption(module + '.requireValidToken');
    let headers = this.buildAuthHeader() || new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(url, {headers: headers})
    .pipe(map(function (res) {
      return new NbAuthResult(true, res, that.getOption('redirect.success'), [],
        that.getOption('defaultMessages'), that.createRefreshedToken(res, token, requireValidToken));
    }), catchError(function (res) {
      return that.handleResponseError(res);
    }));
  }

  protected isRedirectResult(): Observable<boolean> {
    return super.isRedirectResult();
  }

  protected requestJwtToken(code: string, state: {
    email: string,
    url: string,
    from?: string,
    params?: string
  }): Observable<NbAuthResult> {
    const that = this;
    const url = this.getOption('token.endpoint');
    let headers = this.buildAuthHeader() || new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json')
    .append('Accept', 'application/json');
    const redirect = state.from !== undefined
      ? JSON.stringify(state) : JSON.stringify({
        from: that.getOption('redirect.success'),
        params: {}
      });
    return this.http.post(
      url,
      {email: state.email, token: code},
      {headers: headers}
    )
    .pipe(
      map(
        function (res) {
          return new NbAuthResult(true, res, redirect, [], that.getOption('defaultMessages'), that.createToken(res));
        }
      ),
      catchError(
        function (res) {
          return that.handleResponseError(res);
        }
      )
    );
  }
}
