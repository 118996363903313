import { EnvVars } from "./env-vars";

export const environment: EnvVars = {
  production: false,
  baseUrl: 'https://backoffice.dev.logistics.aws.mblty-cloud.services',
  vehicleServicePrefix: '/api-gateway',
  orderServicePrefix: '/api-gateway',
  dadServicePrefix: '/api-gateway',
  sapServicePrefix: '/api-gateway',
  apiVersion: '',
  fedService: 'https://login.microsoftonline.com/964bdbb8-b334-4412-a620-2d0cc3b18666/oauth2/v2.0/authorize',
  redirectUrl: 'https://ui.dev.logistics.aws.mblty-cloud.services/auth/callback',
  callbackUrl: 'https://ui.dev.logistics.aws.mblty-cloud.services/auth/callback',
  clientId: 'f92bd5ab-8c5b-4e89-ba70-fa84be1ca8a9',
  responseType: 'code',
  authorizeScope: 'openid'
};