import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {SelectOptionInterface} from '../@theme/components/select';

@Injectable({providedIn: 'root'})
export class FormattingService {
  dateStringFormats(): string[] {
    return ['DD.MM.YYYY hh:mm:ss', 'DD.MM.YYYY hh:mm', 'DD.MM.YYYY'];
  }

  dateStringIsoFormats(): string[] {
    return ['YYYY-MM-DD hh:mm:ss', 'YYYY-MM-DD hh:mm', 'YYYY-MM-DD'];
  }

  formatDate(dateString: string): Date {
    if (!dateString) {
      return null;
    }
    return moment(dateString, this.dateStringFormats()).toDate();
  }

  usePlaceholder(value: string): string {
    return value || ' - ';
  }

  formatBoolean(flag: boolean): string {
    return this.usePlaceholder(flag ? 'Yes' : 'No');
  }

  dateIsoStringToMoment(stringDate: string): Moment {
    return moment(stringDate, this.dateStringIsoFormats());
  }

  dateStringToMoment(stringDate: string): Moment {
    return moment(stringDate, this.dateStringFormats());
  }

  convertToISO(date: string, hours: string, minutes: string): string {
    const overTime = new Date(`${date},${hours}:${minutes} UTC`);

    return this.usePlaceholder(overTime.toISOString());
  }

  trimSpaces(input: string): string {
    return input.trim();
  }

  addCharIfNotExists(input: string, charToAdd: string): string {
    if (input !== null && input.trim() !== '') {
      input = this.trimSpaces(input);
      if (input.substr(-1) !== charToAdd) {
        input += charToAdd;
      }
    }
    return input;
  }

  pluralView(str: string, quantiry: number) {
    if (quantiry !== 1) {
      return str + 's';
    }

    return str;
  }

  getFromISO(isoDate: string) {
    const [date, time] = isoDate.split(' ');
    const [hours, minutes] = time.split(':');

    return {
      date: date.split('.').reverse().join('-'),
      hours,
      minutes
    };
  }

  generateValues(n = 0, mapFn): SelectOptionInterface[] {
    return Array.from({length: n})
    .map((_, index) => index)
    .map(mapFn)
    .map(v => ({title: String(v), value: String(v)}));
  }

  formatMilesField(miles: number = 0): string {
    return String(Math.round(Number(miles) / 1000));
  }

  formatDistanceValue(deliveryDistance: number): string {
    return deliveryDistance ? Math.ceil(deliveryDistance / 1000) + ' km' : ' – ';
  }

  formatDistanceValueRound(deliveryDistance: number): string {
    return deliveryDistance ? Math.round(deliveryDistance / 1000) + ' km' : ' – ';
  }

  removeUnderscores(value: string) {
    return (!!value && value.length > 0) ? value.replace(/_/g, ' ') : value;
  }

  replaceUnderscoresByValue(value: string, replacedValue: string) {
    return (!!value && value.length > 0) ? value.replace(/_/g, replacedValue) : value;
  }

  parseStringToDateIsoFormat(value: string) {
    // ISO format date yyyy-mm-dd
    const year = parseInt(value.substr(0, 4), 10);
    const month = parseInt(value.substr(5, 2), 10) - 1;
    const day = parseInt(value.substr(8, 2), 10);
    return {
      day: day,
      month: month + 1,
      year: year
    };
  }

}
