import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DadStatusDto, RawDadStatusDto} from 'app/domain/dto/contract.interface';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable(
  {
    providedIn: 'root'
  }
)
export class DADService {

  baseUrl = environment.baseUrl;
  apiVersion = environment.apiVersion;
  dadServicePrefix = environment.dadServicePrefix;

  constructor(private http: HttpClient) {
  }

  getDadStatusHistory(contractId: string): Observable<RawDadStatusDto[]> {
    const url = this.baseUrl + this.dadServicePrefix + this.apiVersion + '/contracts/order/' + contractId + '/history';
    return this.http.get<RawDadStatusDto[]>(url, httpOptions);
  }
}
