import {Type} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../dynamic-field';

export abstract class ContainerAbstract implements FieldConfig {
  abstract name: string;
  abstract items: FieldConfig[][];
  abstract type: Type<ContainerAbstract>;
  group: FormGroup;
}
