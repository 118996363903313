import { Input, Type, Directive } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {subscribedContainerMixin} from 'app/ui/helpers/subscribed-container';
import {FieldConfig, Validator} from '../dynamic-field';
import {SelectOptionInterface} from './select-option.interface';

@Directive()
export abstract class SelectAbstractDirective extends subscribedContainerMixin() implements FieldConfig {
  abstract type: Type<SelectAbstractDirective>;
  @Input() group: FormGroup;
  abstract label: string;
  abstract name: string;
  abstract options: SelectOptionInterface[];
  abstract validations: Validator[];
}
