import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'flat_obj'})
export class FlatObjPipe implements PipeTransform {
  transform(obj: {[key: string]: any}, values: string = '') {
    const orderValues = values.split(',').map(v => v.trim());

    if (Object.keys(obj || {}).length > 0) {
      const objectValues = (orderValues.length > 0 ? orderValues : Object.keys(obj || {}))
        .map(v => obj[v])
        .filter(Boolean)
        .map(v => String(v).trim())
        .filter(Boolean);

      if (objectValues.length > 0) {
        return objectValues.join(', ');
      }
    }

    return ' – ';
  }
}
