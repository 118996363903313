import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AlertMessage, AlertService} from 'app/service/alert.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styles: [`
    pre {
      font-family: inherit;
      margin: 0;
      color: #ffffff;
    }
  `]
})

export class AlertComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  alerts: AlertMessage[] = [];
  alertSubscription: Subscription;

  constructor(private alertService: AlertService) {
  }

  ngOnInit() {
    this.alertSubscription = this.alertService.onAlert(this.id).subscribe(alert => {
      if (alert !== undefined) {
        if (alert.type === 'clear') {
          this.removeAlert(alert);
          return;
        }
      }
      if (alert !== null && !alert.text) {
        return;
      }
      this.alerts.push(alert);
    });
  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
  }

  removeAlert(alert: AlertMessage) {
    this.alerts = this.alerts.filter(x => x.id !== alert.id && x.type !== alert.type);
  }
}
