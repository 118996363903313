import {Component, OnInit} from '@angular/core';
import {Validator} from '../dynamic-field';
import {InputAbstractDirective} from './input.abstract';

@Component({
  selector: 'app-input',
  template: `
    <mat-form-field class="container-fluid mt-auto" [formGroup]="group">
      <input matInput
             [formControlName]="name"
             [placeholder]="label"
             [type]="inputType"
             [maxlength]="maxLength"
             [minlength]="minLength"
      >
      <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
        <mat-error
          *ngIf="group.get(name).hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styles: []
})
export class InputComponent extends InputAbstractDirective implements OnInit {
  type = InputComponent;
  name: string;
  label: string;
  inputType: string;
  maxLength = 0;
  minLength = 0;
  validations: Validator[] = [];
  disabled = false;

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
