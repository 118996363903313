import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'prettyprint'
})
export class PrettyPrintPipe implements PipeTransform {
  transform(val) {
    try {
      const src = JSON.parse(val);

      return JSON.stringify(src, undefined, 4)
        .replace(/ /g, '&nbsp;')
        .replace(/\n/g, '<br/>');
    } catch (e) {
      return '';
    }
  }
}
