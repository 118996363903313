import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Validator} from '../dynamic-field';

@Component({
  selector: 'file-uploader',
  template: `
    <div class="file-uploader">
      <ng-container *ngIf="progressExists">
        <nb-progress-bar *ngIf="progress > 0" [displayValue]="true" [value]="progress"
                         status="warning"></nb-progress-bar>
        <br>
      </ng-container>
      <angular-file-uploader
        #angularFileUploader
        [fileDescription]="fileDescription"
        [isFormPart]="isFormPart"
        [config]="mergedConfig"
        [showFiles]="showFiles"
        (handleChange)="triggerChange($event)">
      </angular-file-uploader>
    </div>
  `
})
export class FileUploaderComponent {
  @Input() showFiles = false;
  @Input() progress;
  @Input() fileDescription: string[];
  @Input() isFormPart = false;
  @Input() config: {} = {};
  @Output() changeHandler: EventEmitter<any> = new EventEmitter<any>();

  callbackScope: any;
  callbackMethod: (scope: any, instance: any) => void;
  type = FileUploaderComponent;

  disabled = false;
  inputType: string;
  label: string;
  maxLength = 0;
  minLength = 0;
  name: string;
  validations: Validator[] = [];

  afuConfig = {
    multiple: true,
    formatsAllowed: '.pdf, .jpeg, .jpg, .tiff, .bmp, .png',
    maxSize: '5',
    uploadAPI: {
      url: '',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8'
      }
    },
    theme: 'dragNDrop',
    hideProgressBar: false,
    hideResetBtn: true,
    hideSelectBtn: false,
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      dragNDropBox: 'Drag N Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded!',
      afterUploadMsg_error: 'Upload Failed!'
    }
  };

  get progressExists() {
    return typeof this.progress !== 'undefined';
  }

  get mergedConfig() {
    return {
      ...this.afuConfig,
      ...this.config
    };
  }

  triggerChange(data) {
    this.changeHandler.emit(data);
  }
}
