import {Component, OnInit} from '@angular/core';
import {FieldConfig} from '../dynamic-field';
import {CardAbstract} from './card.abstract';

@Component({
  selector: 'app-card',
  template: `
    <mat-accordion class="container-fluid">
      <mat-expansion-panel [expanded]="isExpanded" [disabled]="disabled" >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4>{{title}}</h4>
          </mat-panel-title>
          <mat-panel-description>
            {{description}}
          </mat-panel-description>
          <mat-icon>arrow_down</mat-icon>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let rows of childItems;">
          <div class="row">
            <ng-container *ngFor="let item of rows;">
              <div class="col">
                <ng-container appDynamicField [field]="item" [group]="group.controls[item.name]||group">
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  `,
  styles: []
})
export class CardComponent extends CardAbstract implements OnInit {
  type = CardComponent;
  title: string;
  description: string;
  childItems: FieldConfig[] = [];
  isExpanded = false;
  disabled = false;

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
