import {Type} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig, Validator} from '../dynamic-field';

export abstract class DateAbstract implements FieldConfig {
  abstract type: Type<DateAbstract>;
  group: FormGroup;
  abstract name: string;
  abstract label: string;
  abstract validations?: Validator[] = [];
}
