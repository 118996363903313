import {NgModule} from '@angular/core';
import {MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatOptionModule
} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DomSanitizer} from '@angular/platform-browser';

import * as _moment from 'moment';

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule(
  {
    imports: [
      MatSlideToggleModule,
      MatButtonModule,
      MatIconModule,
      MatCardModule,
      MatFormFieldModule,
      MatInputModule,
      MatListModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatMomentDateModule,
      MatSelectModule,
      MatOptionModule,
      MatCheckboxModule,
      MatRadioModule,
      MatProgressSpinnerModule,
      MatTableModule,
      MatPaginatorModule,
      MatSortModule,
      MatToolbarModule,
      MatSidenavModule,
      MatExpansionModule,
      MatTooltipModule
    ],
    exports: [
      MatSlideToggleModule,
      MatButtonModule,
      MatIconModule,
      MatCardModule,
      MatFormFieldModule,
      MatInputModule,
      MatListModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatMomentDateModule,
      MatSelectModule,
      MatOptionModule,
      MatCheckboxModule,
      MatRadioModule,
      MatProgressSpinnerModule,
      MatTableModule,
      MatPaginatorModule,
      MatSortModule,
      MatToolbarModule,
      MatSidenavModule,
      MatExpansionModule,
      MatTooltipModule
    ],
    providers: [
      {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
      {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ]
  }
)
export class MaterialModule {
  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    [
      'add', 'car_registration', 'compare', 'download', 'location',
      'profile', 'search', 'transport', 'back', 'car_return',
      'customizing', 'edit', 'logistic', 'radiobutton', 'services',
      'tyre', 'burger_menu', 'check', 'date', 'expand', 'logout',
      'radiobutton_filled', 'steering', 'upload', 'business',
      'checkbox', 'de_registration', 'forward', 'mail',
      'registration', 'supplier', 'vehicle', 'calendar', 'close',
      'documents', 'home', 'member', 'reject', 'tempo',
      'cancel', 'collapse', 'done', 'info', 'notification',
      'retangle', 'time', 'add_file', 'check_file', 'edit_file', 'text'
    ].forEach(iconName => this.matIconRegistry.addSvgIcon(
      `lsp-${iconName}`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${iconName}.svg`)
    ));
  }
}
