import {Location} from '@angular/common';
import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'back-button',
  template: `
    <div class="back container-fluid" [hidden]="!isVisible">
      <div class="back-button cursor-pointer d-flex align-items-center ml-n1" (click)="go()">
        <mat-icon class="back-icon" svgIcon="lsp-back"></mat-icon>
        BACK
      </div>
    </div>
  `,
  styles: [`
    .back {
      padding-bottom: 24px;
    }

    .back-icon {
      transform: scale(0.5) translateY(-1px)
    }

    .back-button {
      font-size: 10px;
      max-width: 60px;
    }
  `]
})
export class BackButtonComponent {
  url: string;
  isVisible = false;
  home = '/pages/dashboard';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((e: NavigationEnd) => {
      const {urlAfterRedirects} = e;

      this.isVisible = urlAfterRedirects !== this.home;
    });
  }

  go() {
    if (window.history.length > 2) {
      this._location.back();
    }
  }
}
