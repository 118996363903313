import { Input, Type, Directive } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig, Validator} from '../dynamic-field';

@Directive()
export abstract class InputAbstractDirective implements FieldConfig {
  abstract type: Type<InputAbstractDirective>;
  @Input() group: FormGroup;
  abstract name: string;
  abstract label: string;
  abstract inputType: string;
  abstract maxLength?: number;
  abstract minLength?: number;
  abstract validations?: Validator[] = [];
  abstract disabled?: boolean;
}
